<script>
import feather from 'feather-icons';
import ProjectHeader from '../components/projects/ProjectHeader.vue';
import ProjectGallery from '../components/projects/ProjectGallery.vue';
import ProjectInfo from '../components/projects/ProjectInfo.vue';
// import ProjectRelatedProjects from '../components/projects/ProjectRelatedProjects.vue';
import projects from '../data/projects.js'; // Import your project data

export default {
	name: 'SingleProject',
	components: {
		ProjectHeader,
		ProjectGallery,
		ProjectInfo,
		// ProjectRelatedProjects,
	},
	props: {
    id: Number, // Receive the id from the route parameter
	},
	data: () => {
		return {
			// Initialize the projectData as null
			projectData: null,
			singleProjectHeader: {
				singleProjectTitle: 'Video Game Design',
				singleProjectDate: 'Spring 2021',
				singleProjectTag: 'Game Design / Course Project / Group Work',
			},
			projectImages: [
				{
					id: 1,
					title: 'Kabul Project Management UI',
					img: require('@/assets/images/pinball.png'),
				},
				{
					id: 2,
					title: 'Kabul Project Management UI',
					img: require('@/assets/images/GAME.png'),
				},
				{
					id: 3,
					title: 'Kabul Project Management UI',
					img: require('@/assets/images/gameHomepage.png'),
				}
			],
			projectInfo: {
				clientHeading: 'Course Information',
				companyInfos: [
					{
						id: 1,
						title: 'Instructor',
						details: 'Dr. Jinwei Ye',
					},
					{
						id: 2,
						title: 'Course Number',
						details: 'CSC 4263',
					},
					// {
					// 	id: 3,
					// 	title: 'Website',
					// 	details: 'http://fsh.dev.lsuagcenter.net/',
					// },
					// {
					// 	id: 4,
					// 	title: 'Principal investigator (PI)',
					// 	details: 'Dr. Carol Friedland',
					// },
				],
				objectivesHeading: 'Objective',
				objectivesDetails:
					'This course explores the application of aesthetic concepts in human-centered evaluation and the evolution of visual and auditory experiences. It covers graphic software, 3D graphics principles, game development tools, software engineering, and problem-solving. Additionally, it delves into the application of algorithms, automata theory, artificial intelligence, and physical simulations. Emphasizing teamwork and effective communication, the course fosters these skills through collaborative projects and oral presentations.',
				technologies: [
					{
						title: 'Tools & Technologies',
						techs: [
							'Unity',
							'C',
							'C#',
							'HTML',
							'CSS',
							'JavaScript',
						],
					},
				],
				projectDetailsHeading: 'Project Details',
				projectDetails: [
					{
						id: 1,
						details:
							'In this course, I explored game development theory and practice using Unity through individual and group projects. ',
					},
					{
						id: 2,
						details:
							'For my individual project, I created a 3D pinball game called "Jiyoung\'s Market" within the Unity framework. The game offers a casual gaming experience without failure results. Instead of displaying "Fail" at the end of the game, it prompts players with "Ready to Checkout?" The game involves hitting objects on the floor or shelves to earn points while avoiding collisions with customers to prevent additional charges. The game design includes game controls using keyboard keys (e.g., down-arrow, up-arrow, a, d) and features such as main menu options (Quit and Play), ambience elements in the grocery store (shelves, objects, customers), and linked flipper and cashier desk sounds. The game implementation involved various tasks, such as finding assets, building menus, creating the pinball board, organizing grocery objects, adding animations and sounds, implementing a score system, and writing scripts.',
					},
					{
						id: 3,
						details:
							'For the team project, we developed a first-person shooter (FPS) game set in a space/sci-fi environment. Players take on the role of a space commando with the objective of eliminating enemies and surviving as long as possible. The game features multiple weapons and equipment that players can access as they progress. The goal is to survive waves of enemies by acquiring points through kills. The game includes two maps, AI-driven enemies, and success criteria based on clearing two waves. I primarily worked on user interface design, music, button-click screen transitions, and other fundamental game functionality. Additionally, I contributed to implementing a homepage for our team in the project.',
					},
				// 	{
				// 		id: 4,
				// 		details:
				// 			'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil vel illum asperiores dignissimos cumque quibusdam et fugiat voluptatem nobis suscipit explicabo, eaque consequatur nesciunt, fugit eligendi corporis laudantium adipisci soluta? Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt totam dolorum, ducimus obcaecati, voluptas facilis molestias nobis ut quam natus similique inventore excepturi optio ipsa deleniti fugit illo. Unde, amet! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum illo necessitatibus perspiciatis! Aperiam perferendis labore temporibus, eos culpa corporis recusandae quas, fuga voluptatibus nesciunt odit libero tenetur neque consequatur ea.',
				// 	},
				],
				// socialSharingsHeading: 'Share This',
				// socialSharings: [
				// 	{
				// 		id: 1,
				// 		name: 'Twitter',
				// 		icon: 'twitter',
				// 		url: 'https://twitter.com/',
				// 	},
				// 	{
				// 		id: 2,
				// 		name: 'Instagram',
				// 		icon: 'instagram',
				// 		url: 'https://instagram.com/',
				// 	},
				// 	{
				// 		id: 3,
				// 		name: 'Facebook',
				// 		icon: 'facebook',
				// 		url: 'https://facebook.com/',
				// 	},
				// 	{
				// 		id: 4,
				// 		name: 'LinkedIn',
				// 		icon: 'linkedin',
				// 		url: 'https://linkedin.com/',
				// 	},
				// 	{
				// 		id: 5,
				// 		name: 'Youtube',
				// 		icon: 'youtube',
				// 		url: 'https://www.youtube.com/',
				// 	},
				// ],
			},
			relatedProject: {
				relatedProjectsHeading: 'Related Projects',
				relatedProjects: [
					{
						id: 1,
						title: 'Mobile UI',
						img: require('@/assets/images/mobile-project-1.jpg'),
					},
					{
						id: 2,
						title: 'Web Application',
						img: require('@/assets/images/web-project-1.jpg'),
					},
					{
						id: 3,
						title: 'UI Design',
						img: require('@/assets/images/ui-project-2.jpg'),
					},
					{
						id: 4,
						title: 'Kabul Mobile App UI',
						img: require('@/assets/images/mobile-project-2.jpg'),
					},
				],
			},
		};
	},
	computed: {
    selectedProject() {
      // Find the selected project based on its ID
      return this.projectData;
    },
  },
	mounted() {
		feather.replace();
		this.loadProjectData(); 
	},
	methods: {
    loadProjectData() {
      // Find the selected project based on the route parameter (id)
      this.projectData = projects.find(project => project.id === this.id);
    },
  },
};
</script>

<template>
	<div class="container mx-auto mt-10 sm:mt-20">
		<!-- Project header -->
		<ProjectHeader :singleProjectHeader="singleProjectHeader" />

		<!-- Project gallery -->
		<ProjectGallery :projectImages="projectImages" />

		<!-- Project information -->
		<ProjectInfo :projectInfo="projectInfo" />

		<!-- Project related projects -->
		<!-- <ProjectRelatedProjects :relatedProject="relatedProject" /> -->
	</div>
</template>

<style scoped></style>
