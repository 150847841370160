// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 0,
		title: 'I-GUIDE Summer School: GeoAI',
		category: 'GeoAI',
		img: require('@/assets/images/summerSchool.png'),
		url:'/projects/SummerSchool',  
	},
	{
		id: 1,
		title: 'Baton Rouge Crime Dashboard',
		category: 'Web Application',
		img: require('@/assets/images/crimedashboard.png'),
		url:'/projects/IACA/CrimeDashboard',
	},
	{
		id: 2,
		title: 'Flood Safe Home: Version 2',
		category: 'Web Application',
		img: require('@/assets/images/fsh_result.png'),
		url:'/projects/FSH/V2',
	},
	{
		id: 3,
		title: 'Flood Safe Home: Version 1',
		category: 'Web Application',
		img: require('@/assets/images/fsh2.png'),
		url:'/projects/FSH/V1',
	},
	{
		id: 4,
		title: 'A Study on Improving Quality of Life after COVID-19',
		category: 'Grant',
		img: require('@/assets/images/GRI.png'),
		url:'/projects/GRI',
	},
	{
		id: 5,
		title: 'Marshall Plan Scholarship Grant',
		category: 'Grant',
		img: require('@/assets/images/MPS.png'),
		url:'/projects/MPS',
	},
	{
		id: 6,
		title: 'Game Design',
		category: 'Course Project',
		img: require('@/assets/images/GAME.png'),
		url:'/views/GD',
	},
	{
		id: 7,
		title: 'Web GIS',
		category: 'Course Project',
		img: require('@/assets/images/webgis.png'),
		url:'/projects/WebGIS',
	},
	{
		id: 8,
		title: 'Implementation of interactive web map applicable in spatio-temporal data mapping',
		category: 'Master Thesis',
		img: require('@/assets/images/point.gif'),
		url:'/projects/MasterProject',
	}
];




export default projects;
