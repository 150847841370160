import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import GD from '../views/GD.vue';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: 'Jiyoung - Home',
		},
	},
	{
		path: '/about',
		name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/About.vue'),
		meta: {
			title: 'Jiyoung - About',
		},
	},
	{
		path: '/gallery',
		name: 'Gallery',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Gallery.vue'),
		meta: {
			title: 'Jiyoung - Gallery',
		},
	},
	{
		path: '/projects',
		name: 'Projects',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
		meta: {
			title: 'Jiyoung - Projects',
		},
	},
	{
		path: '/projects/SummerSchool',
		name: 'Single Project: Summer School',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/SummerSchool.vue'
			),
		meta: {
			title: 'Jiyoung - Summer School',
		},
	},
	{
		path: '/projects/IACA/CrimeDashboard',
		name: 'Single Project: Crime Dashboard',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/crimeDashboard.vue'
			),
		meta: {
			title: 'Jiyoung - Crime Dashboard',
		},
	},
	{
		path: '/projects/FSH/V2',
		name: 'Single Project: FloodSafeHome V2',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/FSH_V2.vue'
			),
		meta: {
			title: 'Jiyoung - Single Project: FloodSafeHome V2',
		},
	},
	{
		path: '/projects/FSH/V1',
		name: 'Single Project: FloodSafeHome V1',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/FSH_V1.vue'
			),
		meta: {
			title: 'Jiyoung - Single Project: FloodSafeHome V1',
		},
	},
	{
		path: '/projects/GRI',
		name: 'Single Project: Gyeonggi Research Institute',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/GRI.vue'
			),
		meta: {
			title: 'Jiyoung - Single Project: Gyeonggi Research Institute',
		},
	},
	{
		path: '/projects/MPS',
		name: 'Single Project: Marshall Plan Scholarship',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/MPS.vue'
			),
		meta: {
			title: 'Jiyoung - Single Project: Marshall Plan Scholarship',
		},
	},
	{
		path: '/views/GD',
		name: 'Single Project: Game Design',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: GD,
		meta: {
			title: 'Jiyoung - Single Project: Game Design',
		},
	},
	{
		path: '/projects/WebGIS',
		name: 'Single Project: Web GIS',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/WebGIS.vue'
			),
		meta: {
			title: 'Jiyoung - Single Project: Web GIS',
		},
	},
	{
		path: '/projects/MasterProject',
		name: 'Single Project: Master Project',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/MasterProject.vue'
			),
		meta: {
			title: 'Jiyoung - Single Project: Master Project',
		},
	},
	{
		path: '/paper',
		name: 'Paper',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Paper.vue'),
		meta: {
			title: 'Jiyoung - Paper',
		},
	},
	{
		path: '/contact',
		name: 'Contact',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Contact.vue'),
		meta: {
			title: 'Jiyoung - Contact',
		},
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		document.getElementById('app').scrollIntoView();
	},
});

export default router;

/**
 * Below code will display the component/active page title
 * Powered by: Nangialai Jiyoung
 */

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
	// This goes through the matched routes from last to first, finding the closest route with a title.
	// e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
	// `/nested`'s will be chosen.
	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.title);

	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);

	const previousNearestWithMeta = from.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);

	// If a route with a title was found, set the document (page) title to that value.
	if (nearestWithTitle) {
		document.title = nearestWithTitle.meta.title;
	} else if (previousNearestWithMeta) {
		document.title = previousNearestWithMeta.meta.title;
	}

	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(
		document.querySelectorAll('[data-vue-router-controlled]')
	).map((el) => el.parentNode.removeChild(el));

	// Skip rendering meta tags if there are none.
	if (!nearestWithMeta) return next();

	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags
		.map((tagDef) => {
			const tag = document.createElement('meta');

			Object.keys(tagDef).forEach((key) => {
				tag.setAttribute(key, tagDef[key]);
			});

			// We use this to track which meta tags we create so we don't interfere with other ones.
			tag.setAttribute('data-vue-router-controlled', '');

			return tag;
		})
		// Add the meta tags to the document head.
		.forEach((tag) => document.head.appendChild(tag));

	next();
});
